// import all the dependencies
import React from "react";

// define the Jumbotron component
function Jumbotron() {
    return (
        <div className="row page">
            <div className="col">
                <div className="jumbotron jumbotron-fluid p-3">
                    <img id="pic-me" className="img-thumbnail img-fluid float-left" src="images/picture-me.jpg" alt="sophie" />
                    <div id="div-short-intro" className="text-center short-intro mb-3 mt-3">
                        <h2 className="display-4 font-weight-bold my-name">Sophie Mallez</h2>
                        <h4 className="text-white p-2 intro">Full Stack Developer, living in the San Francisco Bay Area.</h4>
                    </div>
                    <div id="div-sections" className="text-center mt-5 mb-5">
                        <a className="btn text-white mr-4 font-weight-bold section p-2" href="#section-AboutMe">About Me!</a>
                        <a className="btn text-white mr-4 ml-4 font-weight-bold section p-2" href="#section-Skills">Skills</a>
                        <a className="btn text-white ml-4 font-weight-bold section p-2" href="#section-Projects">Projects</a>
                    </div>
                    <div id="div-icons" className="text-center mt-4 mb-4">
                        <a className="text-white profile-icon mr-2" href="https://github.com/SophM/" target="_blank" rel="noopener noreferrer"><i className="fab fa-github-square"></i></a>
                        <a className="text-white profile-icon mr-2 ml-2" href="https://linkedin.com/in/sophie-m-571325176" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                        <a className="text-white profile-icon resume ml-2" href="resume/Resume_English_Sophie.pdf" target="_blank" rel="noopener noreferrer"><i className="fas fa-file-alt"></i></a>
                    </div>
                </div>
            </div>
        </div>
    );
};

// export the component so it is available for other files
export default Jumbotron;
