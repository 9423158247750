// import all the dependencies
import React from "react";

const logos = [
    "api-logo.png", 
    "bash-logo.png", 
    "bootstrap-logo.png", 
    "css3-logo.png", 
    "express-logo.png", 
    "firebase-logo.png", 
    "github-logo.png",
    "handlebars-logo.png",
    "html5-logo.png",
    "JavaScript-logo.png",
    "jquery-logo.jpg",
    "json-logo.png",
    "mediaQuery-logo.png",
    "mongodb-logo.png",
    "mysql-logo.png",
    "nodejs-logo.jpg",
    "python-logo.png",
    "r-project-logo.jpg",
    "react-logo.png",
    "rest-api-logo.png",
    "RStudio-logo.png"
];

// define the Skills component
function Skills() {
    return (
        <div className="card-columns card-columns-logo">
            {logos.map((logo, i) => (
                <div className="card" key={i}>
                    <div className="card-body text-center">
                        <img src={`logos/${logo}`} alt={logo} style={{width: "100px"}}/>
                    </div>
                </div>
            ))}
        </div>
    );
};

// export the component so it is available for other files
export default Skills;