// import all the dependencies
import React from "react";

// define the projectCard component
function ProjectCard(props) {
    return (
        <div className="card card-project" style={{width: "18rem"}}>
            <img src={props.image} className="card-img-top" alt={props.name} />
            <div className="card-body text-center">
                <h4 className="card-title font-weight-bold">{props.name}</h4>
                <p className="card-text mb-2">{props.description}</p>
            </div>
        </div>
    );
};

// export the component so it is available for other files
export default ProjectCard;
