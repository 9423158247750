// import all the dependencies
import React from "react";

// define the AboutMe component
function AboutMe() {
    return (
        <div className="card card-aboutme">
            <div className="row ml-2 mr-2 mt-3 mb-3">
                <div className="col-sm-12">
                    <img id="pic-mara-me" className="img-thumbnail img-fluid mt-2 ml-2 mb-2 mr-3 float-left" src="images/mara-me-canoeing.jpg" alt="Mara-and-I-canoeing" />
                    <p className="card-text text-justify pl-2 pr-2 pt-2">
                        After 6 years in Research, as a PhD Graduate and then as a postdoc, I am pursuing the second thing I enjoy the most: coding and building things. Looking for a more stable life, I am seeking new opportunities as a Full Stack Developer.
                    </p>
                    <p className="card-text text-justify pl-2 pr-2">
                        A while ago, in France, I studied Ecology and I liked it so much that I went all the way to the PhD. I
                        specialized in population genetics and invasion biology. Few years back, I moved to the US to do a postdoc on zebra mussels at the University of Minnesota, in Minneapolis.
                    </p>
                    <p className="card-text text-justify pl-2 pr-2">
                        My years in Science and Research taught me a lot. I did a lot of laboratory and field work, I analyzed data, I wrote papers and I presented my research to peers, associations and state agencies. I learnt to learn, to work hard, to pay attention to details and to be rigorous and dedicated.
                    </p>
                    <p className="card-text text-justify pl-2 pr-2">
                        Now, I am taking all those skills with me and I am eager and ready to bring to life ideas and to build slick and responsive front ends.  
                    </p>
                </div>
            </div>
        </div>
    );
};

// export the component so it is available for other files
export default AboutMe;
