// import all the dependencies
import React from "react";

// define the overlay component
function Overlay(props) {
    return (
        <div className="overlay-tech w-75 mx-auto mb-2">
            <ul>
                {props.technologies.map((technology, i) => (
                    <li className="font-weight-bold" key={i}>{technology}</li>
                ))}
            </ul>
            {props.children}
        </div>
    );
};

// export the component so it is available for other files
export default Overlay;