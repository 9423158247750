// import all the dependencies
import React from "react";

// define LinkBtn component
function LinkBtn(props) {
    if (props.deployedLink === "not deployed yet") {
        return (
            <div className="mx-auto">
                <p className="font-weight-bold" style={{color: "coral"}}>Not deployed yet!</p>
                <a className="btn m-1 text-white btn-git font-weight-bold" href={props.githubLink} target="_blank" rel="noopener noreferrer">GitHub Repo</a>
            </div>
        );
    } else if (props.deployedLink === undefined) {
        return (
            <div className="mx-auto">
                <p className="font-weight-bold" style={{color: "coral"}}>CLI - no deployed version!</p>
                <a className="btn m-1 text-white btn-git font-weight-bold" href={props.githubLink} target="_blank" rel="noopener noreferrer">GitHub Repo</a>
            </div>
        );
    } else {
        return (
            <div className="mx-auto">
                <a className="btn m-1 btn-deploy text-white font-weight-bold" href={props.deployedLink} target="_blank" rel="noopener noreferrer">Try it!</a>
                <a className="btn m-1 text-white btn-git font-weight-bold" href={props.githubLink} target="_blank" rel="noopener noreferrer">GitHub Repo</a>
            </div>
        );
    } 
};

// export the component so it is available for other files
export default LinkBtn;