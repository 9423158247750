// import all the dependencies
import React, { Component } from 'react';
import './App.css';
import projects from "./projects.json";
import ProjectCards from "./components/projectCard";
import Overlay from "./components/overlay";
import LinkBtn from "./components/linkBtn";
import Jumbo from "./components/jumbotron";
import AboutMe from "./components/aboutMe";
import Skills from "./components/skills";
import NavBar from "./components/navbar";
import ScrollableAnchor from 'react-scrollable-anchor';


// create a class App to define a component
class App extends Component {

  state = {
    projects,
  };

  render() {
    return (
      <div>
        <ScrollableAnchor id={"section-landing"}>
          <div id="landing">
            <Jumbo />
          </div>
        </ScrollableAnchor>
        <NavBar />
        <ScrollableAnchor id={"section-AboutMe"}>
          <div>
            <div className="container w-75 p-0">
              <br></br>
              <br></br>
              <h2 className="mt-5 mb-3 font-weight-bold name-section text-center"> -- About Me -- </h2>
              <AboutMe />
              <br></br>
              <br></br>
              <br></br>
              <br></br>
            </div>
            <hr class="line-separator"></hr>
          </div>
        </ScrollableAnchor>
        <ScrollableAnchor id={"section-Skills"}>
          <div>
            <br></br>
            <br></br>
            <h2 className="mt-5 mb-3 font-weight-bold name-section text-center"> -- Skills -- </h2>
            <div className="container container-logo w-75 mt-4 mb-5 pt-3">
              <Skills />
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <hr class="line-separator"></hr>
          </div>
        </ScrollableAnchor>
        <ScrollableAnchor id={"section-Projects"}>
          <div>
            <br></br>
            <br></br>
            <h2 className="mt-5 mb-3 font-weight-bold name-section text-center"> -- Projects -- </h2>
            <div className="container mt-5 mb-5">
              <div className="card-columns card-columns-project">
                {this.state.projects.map(project => (
                  <div className="project-block" key={project.id}>
                    <ProjectCards
                      id={project.id}
                      name={project.name}
                      image={project.image}
                      description={project.description}
                    />
                    <Overlay
                      technologies={project.technologies}
                    >
                      <LinkBtn
                        deployedLink={project.deployedLink}
                        githubLink={project.githubLink}
                      />
                    </Overlay>
                  </div>
                ))}
              </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
          </div>
        </ScrollableAnchor>
      </div>
    )
  }
}

// export the component so it is available for other files
export default App;
