// import all the dependencies
import React, { Component } from "react";
import Collapse from "react-bootstrap/Collapse";

// define the NavBar component
class NavBar extends Component {

    constructor(props, context) {
        super(props, context);
    
        this.state = {
          open: false,
        };
    }

    render() {
        const { open } = this.state;

        return (
            <nav className="navbar navbar-expand-lg navbar-dark navbar-custom border-bottom pt-0 pb-0 sticky-top">
                <a className="navbar-brand" href="#section-landing"><h3 className="font-weight-bold text-white my-name-navbar ml-2 mr-2 mt-1">Sophie Mallez</h3></a>
                <span className="pt-2 align-left ml-2">
                    <a className="text-white profile-icon profile-icon-navbar" href="https://github.com/SophM/" target="_blank" rel="noopener noreferrer"><i className="fab fa-github-square"></i></a>
                </span>
                <span className="pt-2 align-left ml-2">
                    <a className="text-white profile-icon profile-icon-navbar" href="https://linkedin.com/in/sophie-m-571325176" target="_blank" rel="noopener noreferrer"><i className="fab fa-linkedin"></i></a>
                </span>
                <span className="pt-2 align-left ml-2">
                    <a className="text-white profile-icon profile-icon-navbar resume" href="resume/Resume_English_Sophie.pdf" target="_blank" rel="noopener noreferrer"><i className="fas fa-file-alt"></i></a>
                </span>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" onClick={() => this.setState({ open: !open })} aria-expanded={open} aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <Collapse className="collapse navbar-collapse" in={this.state.open}>
                    <div className="navbar-nav flex-row justify-content-end" id="navbarNav">
                        <a className="nav-link text-white section section-navbar mr-3 mb-1 mt-1 p-2" href="#section-AboutMe">About Me!</a>
                        <a className="nav-link text-white section section-navbar mr-3 ml-3 mb-1 mt-1 p-2" href="#section-Skills">Skills</a>
                        <a className="nav-link text-white section section-navbar ml-3 mb-1 mt-1 p-2" href="#section-Projects">Projects</a>
                    </div>
                </Collapse>
            </nav>
        );
    };
};

// export the component so it is available for other files
export default NavBar;
